// ACTIONS A EFFECTUER SI ON EST SUR MOBILE
var d = document.documentElement,
    c = "ontouchstart" in window || navigator.msMaxTouchPoints;

if (c) {
    // On est sur mobile
    $("#cadre_permanent").css("border-right-width", "3vw"); 
} else {
	// On est sur ordi

}


// POPOVER FOOTER
if ($('#legal-mentions').length) {

	$("[data-toggle=popover]").popover({
		html:true,
		content: function(){ return $('#legal-mentions').html()} 
	});
}


// CORRECTIONS SOUS EDGE ET IE
if (/MSIE 10/i.test(navigator.userAgent)) {
   // This is internet explorer 10
   var navigateur = 'IE10';
}

if (/MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent)) {
    // This is internet explorer 9 or 11
    var navigateur = 'IE9-11';
}

if (/Edge\/\d./i.test(navigator.userAgent)){
   // This is Microsoft Edge
   var navigateur = 'Edge';
}

if (navigateur == 'IE10' || navigateur == 'IE9-11' || navigateur == 'Edge') {
	$("#svg_accueil_basgauche").css("display", "none"); 
	$("#remplacement_svg_accueil_basgauche").css("display", "block"); 
}

// CALAGE LOGO ACCUEIL
/*function smooth_responsive(target_div, css_property, from_value, to_value, from_width, to_width) {

	// On récupère la largeur du viewport
	var viewport_width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

	// Si on est déjà avant la from_width ou après la to_width
	if(viewport_width <= from_width) {
		$(target_div).css(css_property, from_value); 
	}

	if(viewport_width >= to_width) {
		$(target_div).css(css_property, to_value); 
	}

	// Si on est bien etre les 2
	if(viewport_width >= from_width && viewport_width <= to_width) {
		
		var full_distance = to_width - from_width;
		var current_distance = viewport_width - from_width;
		var ratio = current_distance / full_distance;

		var css_value_gap = to_value - from_value;

		var new_css_value = from_value * ratio;
	}
}

$(window).resize(function(){ 
	var test = smooth_responsive('#phrase_haut_page', 'top', '20%', '60%', 400, 800);
});*/

// Initialisation boutons facebook
(function(d, s, id) {
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s); js.id = id;
  js.src = "//connect.facebook.net/fr_FR/sdk.js#xfbml=1&version=v2.8&appId=1797832130536520";
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));

// Initialisation bouton twitter
window.twttr = (function(d, s, id) {
  var js, fjs = d.getElementsByTagName(s)[0],
    t = window.twttr || {};
  if (d.getElementById(id)) return t;
  js = d.createElement(s);
  js.id = id;
  js.src = "https://platform.twitter.com/widgets.js";
  fjs.parentNode.insertBefore(js, fjs);

  t._e = [];
  t.ready = function(f) {
    t._e.push(f);
  };

  return t;
}(document, "script", "twitter-wjs"));


// ANIMATION ZONE VIOLETTE DEBUT
$( document ).ready(function() {

	if ($('#svg_zone_violette').length) {

		var s = Snap('#svg_zone_violette');

		var zoneviolette = Snap.select('#path_zone_violette');

		zoneviolette.animate({ d: "M 5,0 100,0 Q 100,100 100,100 L 5,100 5,50 Z" }, 1000, mina.easein, function(){
			zoneviolette.animate({ d: "M 45,0 100,0 Q 100,100 100,100 L 45,100 5,50 Z" }, 1000, mina.easein, function(){
				zoneviolette.animate({ d: "M 6.7,48 6.7,48 Q 7.2,50 6.7,52 L 6.7,52 5,50 Z" }, 1000, mina.easeout);
			});
		});

		var contourblanc = Snap.select('#path_contour_blanc');

		contourblanc.animate({ d: "M 5,0 100,0 Q 100,100 100,100 L 5,100 5,50 Z" }, 1000, mina.easein, function(){
			contourblanc.animate({ d: "M 45,0 100,0 Q 100,100 100,100 L 45,100 5,50 Z" }, 1000, mina.easein, function(){
				contourblanc.animate({ d: "M 6.7,48 6.7,48 Q 7.2,50 6.7,52 L 6.7,52 5,50 Z" }, 1000, mina.easeout);
			});
		});

		var triangle = Snap.select('#path_petit_triangle');

		triangle.animate({ d: "M 7,50 11,45 Q 13,50 11,55 Z" }, 1000, mina.easein, function(){
			triangle.animate({ d: "M 7,50 11,45 Q 13,50 11,55 Z" }, 1000, mina.easein, function(){
				triangle.animate({ d: "M 7,50 7,50 Q 7,50 7,50 Z" }, 1000, mina.easein);
			});
		});

	}

});


// ANIMATION CIBLE
$(document).on( 'scroll', function(){

    if ($('#groupe_etiquette_vous.in-view').length) {

		var s = Snap('#svg_animation_cible');

		var animcible_triangle_mask2 = s.polygon(11,50, 100,50 , 100,50).attr({ id:'animcible_triangle_mask2' });
		var animcible_triangle_mask3 = s.polygon(11,50, 100,50 , 100,50).attr({ id:'animcible_triangle_mask3' });
		var animcible_triangle_mask1 = s.polygon(11,50, 100,50 , 100,50).attr({ id:'animcible_triangle_mask1' });
		var animcible_triangle = s.polygon(11,50, 100,50 , 100,50).attr({ id:'animcible_triangle' });
		var animcible_rond3 = s.circle(10,50,0).attr({ id:'animcible_rond3' });
		var animcible_rond2 = s.circle(10,50,0).attr({ id:'animcible_rond2' });
		var animcible_rond1 = s.circle(10,50,0).attr({ id:'animcible_rond1' });
		var animcible_rond3_masked = s.circle(10,50,18).attr({ id:'animcible_rond3_masked' });
		var animcible_rond2_masked = s.circle(10,50,12).attr({ id:'animcible_rond2_masked' });
		var animcible_rond1_masked = s.circle(10,50,6).attr({ id:'animcible_rond1_masked' });
		var txt1 = s.text(0, 13, "testestestestest").attr({ id:'txt1' });

		animcible_rond1_masked.attr({ mask: animcible_triangle_mask1 });
		animcible_rond2_masked.attr({ mask: animcible_triangle_mask2 });
		animcible_rond3_masked.attr({ mask: animcible_triangle_mask3 });

		setTimeout(function () {
			animcible_rond1.animate({ r: "6" }, 300, mina.easeOutQuad);
		}, 0);

		setTimeout(function () {
			animcible_rond2.animate({ r: "12" }, 300, mina.easeOutQuad);
		}, 100);

		setTimeout(function () {
			animcible_rond3.animate({ r: "17.9" }, 300, mina.easeOutQuad);
		}, 200);

		var duree_anim = 1000;
		var ease = mina.easein;

		setTimeout(function () {

		    animcible_triangle.animate({ points: "11,50, 100,0 , 100,100" }, duree_anim, ease);
			animcible_triangle_mask1.animate({ points: "11,50, 100,0 , 100,100" }, duree_anim, ease);
			animcible_triangle_mask2.animate({ points: "11,50, 100,0 , 100,100" }, duree_anim, ease);
			animcible_triangle_mask3.animate({ points: "11,50, 100,0 , 100,100" }, duree_anim, ease);

		}, 500);

		$(document).off('scroll');

	}

});


// FORMULAIRE AJAX : Contact
if (document.getElementById('form_contact')) { 

	$(document).ready(function() {
	    // Lorsque je soumets le formulaire
	    $('#form_contact').on('submit', function(e) {
	        e.preventDefault(); // J'empêche le comportement par défaut du navigateur, c-à-d de soumettre le formulaire
	 
	        var $this = $(this); // L'objet jQuery du formulaire
	 
	        // Je récupère les valeurs
	        var mail = $('#email').val();
	        var nom = $('#nom').val();
	        var telephone = $('#telephone').val();
	        var message = $('#message').val();

	        var data = $(this).serializeArray(); // convert form to array


	        // Je vérifie une première fois pour ne pas lancer la requête HTTP
	        // si je sais que mon PHP renverra une erreur
	        if(nom === '' || email === '' || message === '') {
	            alert("Les champs marqués d'une étoile (*) doivent êtres remplis");
	        } else {resultat = 0;

	        	// Appel ReCaptcha et ajout de la réponse ReCaptcha aux datas
	        	grc_site_key = '6LfqZtUUAAAAAKhlSw9yUZEyvQ1axrnOoIO6cXr4';
	            grecaptcha.ready(function() {
	                grecaptcha.execute(grc_site_key, {action: 'submit_contact_form'}).then(function(token) {

	                    // Une fois le token récupéré, ajout aux datas et appel ajax
	                    data.push({name: 'recaptcha_token', value: token});

			            // Envoi de la requête HTTP en mode asynchrone
			            $.ajax({
			                url: $this.attr('action'), // Le nom du fichier indiqué dans le formulaire
			                type: $this.attr('method'), // La méthode indiquée dans le formulaire (get ou post)
			                data: $.param(data), // Je sérialise les données (j'envoie toutes les valeurs présentes dans le formulaire)
			                success: function(resultat) { // Je récupère la réponse du fichier PHP
			                	console.log(resultat);
			                    if(resultat == 'ok') {
			                        document.getElementById('confirmation_envoi_mail_ok').style.display = 'block';
			                    } else {
			                        document.getElementById('confirmation_envoi_mail_fail').style.display = 'block';
			                    }
			                }
			            });
			        });
            	});
	        }
	    });
	});
};
// FIN FORMULAIRE AJAX : Contact